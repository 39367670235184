import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CoastalUniversityPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Coastal University" />
    <h1 style={styles.header}>Coastal University</h1>
    <div style={styles.body}>
      <h3 style={{...styles.italic, ...styles.primaryText}}>Knowledge is Your Best Investment</h3>
      <p>
        As we’ve discovered, our happiest and most successful clients are those who stay on top of the latest 
        topics and information about the market, investing techniques, tax advantages and what it means to be a 
        high-net-worth investor.
      </p>
      <p>
        To help you succeed on your financial journey, we’re only too happy to provide you white papers, articles and 
        other information we think will make your journey smoother.
      </p>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default CoastalUniversityPage
